
<div class="flex flex-col">
  <div class="w-[30rem] m-auto max-w-full">
    <p-timeline [value]="steps" layout="horizontal" align="top" *ngIf="mode == 'register'">
      <ng-template pTemplate="content" let-item>
        <div class="inline-block w-max text-center -translate-x-1/2 ml-2 mt-2" [class]="currentStep > (steps.indexOf(item) + 1) ? 'past' : currentStep == (steps.indexOf(item) + 1) ? 'current' : 'future'">{{ item.label | translate }}</div>
      </ng-template>
      <ng-template pTemplate="marker" let-item>
        <div class="p-timeline-event-marker" [class]="currentStep > (steps.indexOf(item) + 1) ? 'past' : currentStep == (steps.indexOf(item) + 1) ? 'current' : 'future'">
          <div class="text-green-900 translate-y-[-0.4rem] text-xl leading-[1rem]" *ngIf="currentStep > (steps.indexOf(item) + 1)">✓</div>
        </div>
      </ng-template>
    </p-timeline>
  </div>
  <div class="overflow-x-clip" [ngClass]="mode == 'register' ? 'overflow-y-auto max-h-[calc(100vh-18rem)]' : ''">
    <div class="relative overflow-x-hidden" [ngClass]="mode == 'register' ? 'h-[calc(100vh-18rem)] overflow-y-auto' : ''">
      <div
        class="switch-page w-full max-w-3xl md:mt-0 xl:p-0 mr-auto"
        [class]="(currentStep == 1 ? 'active' : currentStep > 1 ? 'past' : 'future') + ' ' + (mode == 'register' ? 'h-[calc(100vh-18rem)] overflow-y-auto' : '')"
      >
        <div class="md:space-y-6 px-1 sm:px-4" [class]="pv ? '' : 'p-0 sm:p-0 sm:px-0'" action="#" [formGroup]="credentialForm">
          <!-- Personal Title -->
          <div class="grid grid-cols-12">
            <label
              for="email"
              class="col-span-12 md:col-span-2 block mb-2 text-base font-medium text-green-900 dark:text-white pr-1"
            >{{ 'Personal Title' | translate }} <span class="text-red-500">*</span></label
            >
            <div class="col-span-12 md:col-span-10 mb-4">
              <div class=" flex flex-row">
                <div class="mr-4 cursor-pointer" (click)="disableUserInfo ? null : credentialForm.controls['personalTitle'].setValue('Mr.')">
                  <input type="radio" name="personalTitle" formControlName="personalTitle" class="rounded-full checked:bg-green-600 checked:hover:bg-green-600 checked:active:bg-green-600 checked:focus:bg-green-600 focus:outline-none focus:ring-green-600" value="Mr."
                         [attr.disabled]="disableUserInfo ? '' : null"/>
                  <span class="text-sm text-gray-600 ml-2">{{ 'Mr.' | translate }}</span>
                </div>
                <div class="mr-4 cursor-pointer" (click)="disableUserInfo ? null : credentialForm.controls['personalTitle'].setValue('Ms.')">
                  <input type="radio" name="personalTitle" formControlName="personalTitle" class="rounded-full checked:bg-green-600 checked:hover:bg-green-600 checked:active:bg-green-600 checked:focus:bg-green-600 focus:outline-none focus:ring-green-600" value="Ms."
                         [attr.disabled]="disableUserInfo ? '' : null"/>
                  <span class="text-sm text-gray-600 ml-2">{{ 'Ms.' | translate }}</span>
                </div>
                <div class="mr-4 cursor-pointer" (click)="disableUserInfo ? null : credentialForm.controls['personalTitle'].setValue('Mrs.')">
                  <input type="radio" name="personalTitle" formControlName="personalTitle" class="rounded-full checked:bg-green-600 checked:hover:bg-green-600 checked:active:bg-green-600 checked:focus:bg-green-600 focus:outline-none focus:ring-green-600" value="Mrs."
                         [attr.disabled]="disableUserInfo ? '' : null"/>
                  <span class="text-sm text-gray-600 ml-2">{{ 'Mrs.' | translate }}</span>
                </div>
              </div>
              <div
                *ngIf="credentialForm.controls['personalTitle'].invalid && (credentialForm.controls['personalTitle'].dirty || credentialForm.controls['personalTitle'].touched)"
                class="text-red-600 col-span-10 text-xs mt-1"
              >
                <div *ngIf="credentialForm.controls['personalTitle'].errors?.['required']">
                  {{ 'Personal title is required.' | translate }}
                </div>
              </div>
            </div>
          </div>
          <!-- Name in English -->
          <div class="grid grid-cols-12 ">
            <div class="col-span-12 md:col-span-2">
              <label
                for="email"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white pr-1 my-2"
              ><span class="text-base font-medium text-green-900 dark:text-white">{{ 'Name in English' | translate }}</span> <span class="text-red-500">*</span></label
              >
            </div>
            <div class="col-span-12 md:col-span-10 grid grid-cols-12">
              <div class="col-span-12 pb-2 md:col-span-6 md:pr-2 md:pb-0 mb-2">
                <span class="p-float-label relative">
                  <input
                    pInputText
                    name="lastName"
                    id="lastName"
                    class=" border border-gray-300 text-gray-900 text-sm rounded-3xl focus:ring-green-600 focus:border-green-600 focus:outline-green-600 block w-full p-2.5 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    required=""
                    formControlName="lastName"
                    [attr.disabled]="disableUserInfo ? '' : null"
                  />

                  <label
                    for="lastName"
                    class=""
                  >{{ 'Surname in English' | translate }}</label>
                </span>

                <div
                  *ngIf="credentialForm.controls['lastName'].invalid && (credentialForm.controls['lastName'].dirty || credentialForm.controls['lastName'].touched)"
                  class="text-red-600 col-span-10 text-xs mt-1"
                >
                  <div *ngIf="credentialForm.controls['lastName'].errors?.['required']">
                    {{ 'Surname is required.' | translate }}
                  </div>
                </div>
              </div>
              <div class="col-span-12 pb-2 md:col-span-6 md:pr-2 md:pb-0 mb-4">
                <span class="p-float-label relative">
                  <input
                    pInputText
                    name="firstName"
                    id="firstName"
                    class=" border border-gray-300 text-gray-900 text-sm rounded-3xl focus:ring-green-600 focus:border-green-600 focus:outline-green-600 block w-full p-2.5 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required=""
                    formControlName="firstName"
                    [attr.disabled]="disableUserInfo ? '' : null"
                  />
                  <label
                    for="lastName"
                    class=""
                  >{{ 'Other names in English' | translate }}</label
                  >
                </span>

                <div
                  *ngIf="credentialForm.controls['firstName'].invalid && (credentialForm.controls['firstName'].dirty || credentialForm.controls['firstName'].touched)"
                  class="text-red-600 col-span-10 text-xs mt-1"
                >
                  <div *ngIf="credentialForm.controls['firstName'].errors?.['required']">
                    {{ 'Other names is required.' | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Name in Chinese -->
          <div class="grid grid-cols-12 ">
            <div class="col-span-12 md:col-span-2">
              <label
                for="lastName"
                class="mb-2 text-sm font-medium text-gray-500 dark:text-white hidden md:invisible"
              >{{ 'Surname in Chinese' | translate }}</label
              >
              <label
                for="email"
                class="col-span-2 block mb-2 text-sm font-medium text-gray-900 dark:text-white my-2"
              ><span class="text-base font-medium text-green-900 dark:text-white">{{ 'Name in Chinese' | translate }}</span></label
              >
            </div>
            <div class="col-span-12 md:col-span-10 grid grid-cols-12">
              <div class="col-span-12 pb-2 md:col-span-6 md:pr-2 md:pb-0 mb-2">
                <span class="p-float-label relative">
                  <input
                    pInputText
                    name="lastNameZh"
                    id="lastNameZh"
                    class=" border border-gray-300 text-gray-900 text-sm rounded-3xl focus:ring-green-600 focus:border-green-600 focus:outline-green-600 block w-full p-2.5 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    formControlName="lastNameZh"
                    [attr.disabled]="disableUserInfo ? '' : null"
                  />
                  <label
                    for="lastName"
                    class=""
                  >{{ 'Surname in Chinese' | translate }}</label
                  >
                </span>
              </div>
              <div class="col-span-12 pb-2 md:col-span-6 md:pr-2 md:pb-0 mb-4">
                <span class="p-float-label relative">
                  <input
                    pInputText
                    name="firstNameZh"
                    id="firstNameZh"
                    class=" border border-gray-300 text-gray-900 text-sm rounded-3xl focus:ring-green-600 focus:border-green-600 focus:outline-green-600 block w-full p-2.5 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    formControlName="firstNameZh"
                    [attr.disabled]="disableUserInfo ? '' : null"
                  />
                  <label
                    for="lastName"
                    class=""
                  >{{ 'Other names in Chinese' | translate }}</label
                  >
                </span>
              </div>
            </div>
          </div>
          <!-- Contact -->
          <div class="grid grid-cols-12">
            <div class="col-span-12 md:col-span-2">
              <label
                for="email"
                class="col-span-2 block mb-2 text-sm font-medium text-gray-900 dark:text-white pr-1 my-2"
              ><span class="text-base font-medium text-green-900 dark:text-white">{{ 'Contact' | translate }}</span> <span class="text-red-500">*</span></label
              >
            </div>
            <div class="col-span-12 md:col-span-10 grid grid-cols-12 mb-4 md:mb-2">
              <div class="col-span-12 pb-2 md:col-span-6 md:pr-2 md:pb-0 mb-2 md:mb-0">
                <div class="flex flex-row">
                  <span class="p-float-label relative flex-grow">
                    <input
                      pInputText
                      name="mobilePhone"
                      id="mobilePhone"
                      class=" border border-gray-300 text-gray-900 text-sm rounded-3xl focus:ring-green-600 focus:border-green-600 focus:outline-green-600 block w-full p-2.5 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder=""
                      formControlName="mobilePhone"
                      autocomplete="off"
                    />
                    <label
                      for="lastName"
                      class=""
                    >{{ 'Phone Number' | translate }}</label
                    >
                  </span>
                  <div *ngIf="mode == 'profile'" class="!mt-0 !mb-0 !ml-1">
                    <button
                      class="justify-center text-white focus:outline-none font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 mr-1"
                      (click)="gotoSecurityPage()"
                      [class]="['bg-notus-primary-600', 'hover:bg-notus-primary-700', 'focus:ring-notus-primary-300', 'md:ml-0']"
                    >
                      <fa-icon [icon]="faEdit"></fa-icon>
                    </button>
                  </div>
                </div>
                <div
                  *ngIf="credentialForm.controls['mobilePhone'].invalid"
                  class="text-red-600 col-span-12 md:col-span-10 text-xs mt-1"
                >
                  <div *ngIf="credentialForm.controls['mobilePhone'].errors?.['phoneused']">
                    {{ 'Phone number had been used.' | translate }}
                  </div>
                  <div *ngIf="credentialForm.controls['mobilePhone'].errors?.['phoneformat']">
                    {{ 'Please enter a valid phone number.' | translate }}
                  </div>
                </div>
              </div>
              <div class="col-span-12 pb-0 md:col-span-6 md:pr-2 md:pb-0 md:mb-0">
                <div class="flex flex-row">
                  <span class="p-float-label relative flex-grow">
                    <input
                      pInputText
                      type="email"
                      name="email"
                      id="email"
                      class="border border-gray-300 text-gray-900 text-sm rounded-3xl focus:ring-green-600 focus:border-green-600 focus:outline-green-600 block w-full p-2.5 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:outline-0 "
                      placeholder=""
                      formControlName="email"
                      autocomplete="off"
                    />
                    <label
                      for="lastName"
                      class=""
                    >{{ 'Email Address' | translate }}</label
                    >
                  </span>
                  <div *ngIf="mode == 'profile'" class="!mt-0 !mb-0 !ml-1">
                    <button
                      class="justify-center text-white focus:outline-none font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 mr-1"
                      (click)="gotoSecurityPage()"
                      [class]="['bg-notus-primary-600', 'hover:bg-notus-primary-700', 'focus:ring-notus-primary-300', 'md:ml-0']"
                    >
                      <fa-icon [icon]="faEdit"></fa-icon>
                    </button>
                  </div>
                </div>
                <div
                  *ngIf="credentialForm.controls['email'].invalid"
                  class="text-red-600 col-span-12 md:col-span-10 text-xs mt-1"
                >
                  <div *ngIf="credentialForm.controls['email'].errors?.['emailused']">
                    {{ 'Email address had been used.' | translate }}
                  </div>
                  <div *ngIf="credentialForm.controls['email'].errors?.['emailformat']">
                    {{ 'Email address format is incorrect.' | translate }}
                  </div>
                </div>
              </div>
              <div
                *ngIf="credentialForm.invalid"
                class="text-red-600 col-span-12 text-xs mt-1"
              >
                <div *ngIf="credentialForm.controls['email'].errors?.['emailandphonemissing'] && credentialForm.controls['mobilePhone'].errors?.['emailandphonemissing']">
                  {{ 'Please provider either phone or email.' | translate }}
                </div>
                <div *ngIf="credentialForm.errors?.['emailorphoneused']">
                  {{ 'Phone number or email address had been used.' | translate }}
                </div>
              </div>
            </div>
          </div>
          <!-- Password -->
          <div class="grid grid-cols-12 mt-4 mb-6" *ngIf="mode == 'register'">
            <label
              for="password"
              class="col-span-12 md:col-span-2 block mb-2 text-sm font-medium text-gray-900 dark:text-white pr-1 my-2"
            ><span class="text-base font-medium text-green-900 dark:text-white">{{ 'Password' | translate }}</span> <span class="text-red-500">*</span></label
            >
            <div class="col-span-12 md:col-span-10 pr-2 relative">
              <input
                [type]="isPasswordVisible ? 'text' : 'password'"
                name="password"
                id="password"
                placeholder=""
                class=" border border-gray-300 text-gray-900 text-sm rounded-3xl focus:ring-green-600 focus:border-green-600 focus:outline-green-600 block w-full p-2.5 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:outline-0"
                required=""
                formControlName="password"
                (input)="checkPassword($event)"
                autocomplete="new-password"
              />
              <fa-icon class="absolute p-2.5 leading-5 top-0 right-4 text-gray-400" *ngIf="!isPasswordVisible" [icon]="faEyeSlash" (click)="togglePassword()"></fa-icon>
              <fa-icon class="absolute p-2.5 leading-5 top-0 right-4 text-gray-400" *ngIf="isPasswordVisible" [icon]="faEye" (click)="togglePassword()"></fa-icon>
            </div>
            <div class="col-span-12 md:col-span-2"></div>
            <div class="col-span-12 md:col-span-10 pr-2">
              <div
                *ngIf="credentialForm.controls['password'].invalid && (credentialForm.controls['password'].dirty || credentialForm.controls['password'].touched)"
                class="text-red-600 col-span-12 md:col-span-10 text-xs mt-1"
              >
                <div *ngIf="credentialForm.controls['password'].errors?.['required']">
                  {{ 'Password is required.' | translate }}
                </div>
                <div *ngIf="credentialForm.controls['password'].errors?.['requirement']">
                  {{ 'Password does not fulfill requirement.' | translate }}
                </div>
              </div>
              <div class="text-xs mt-2">
                <div class="text-gray-600">{{ 'Password requirements:' | translate }}</div>
                <ul>
                  <li [class]="!isPasswordHasLowercase ? 'text-gray-600' : 'text-green-700'">
                    <div class="w-4 inline-block">
                      <fa-icon [icon]="faCheck" *ngIf="isPasswordHasLowercase"></fa-icon>
                      <fa-icon [icon]="faCircle" *ngIf="!isPasswordHasLowercase" size="xs"></fa-icon>
                    </div>
                    {{ 'One lowercase character' | translate }}
                  </li>
                  <li [class]="!isPasswordHasUppercase ? 'text-gray-600' : 'text-green-700'">
                    <div class="w-4 inline-block">
                      <fa-icon class="" [icon]="faCheck" *ngIf="isPasswordHasUppercase"></fa-icon>
                      <fa-icon [icon]="faCircle" *ngIf="!isPasswordHasUppercase" size="xs"></fa-icon>
                    </div>
                    {{ 'One uppercase character' | translate }}
                  </li>
                  <li [class]="!isPasswordHasNumber ? 'text-gray-600' : 'text-green-700'">
                    <div class="w-4 inline-block">
                      <fa-icon class="" [icon]="faCheck" *ngIf="isPasswordHasNumber"></fa-icon>
                      <fa-icon [icon]="faCircle" *ngIf="!isPasswordHasNumber" size="xs"></fa-icon>
                    </div>
                    {{ 'One number' | translate }}
                  </li>
                  <li [class]="!isPasswordLength8 ? 'text-gray-600' : 'text-green-700'">
                    <div class="w-4 inline-block">
                      <fa-icon class="" [icon]="faCheck" *ngIf="isPasswordLength8"></fa-icon>
                      <fa-icon [icon]="faCircle" *ngIf="!isPasswordLength8" size="xs"></fa-icon>
                    </div>
                    {{ '8 characters minimum' | translate }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- Repeat Password -->
          <div class="grid grid-cols-12 mt-4 mb-4" *ngIf="mode == 'register'">
            <label
              for="repeatPassword"
              class="col-span-12 md:col-span-2 block mb-2 text-sm font-medium text-gray-900 dark:text-white my-2"
            ><span class="text-base font-medium text-green-900 dark:text-white">{{ 'Repeat Password' | translate }}</span> <span class="text-red-500">*</span></label
            >
            <div class="col-span-12 md:col-span-10 pr-2 relative">
              <input
                [type]="isRepeatPasswordVisible ? 'text' : 'password'"
                name="repeatPassword"
                id="repeatPassword"
                placeholder=""
                class=" border border-gray-300 text-gray-900 text-sm rounded-3xl focus:ring-green-600 focus:border-green-600 focus:outline-green-600 block w-full p-2.5 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:outline-0"
                required=""
                formControlName="repeatPassword"
                autocomplete="new-password"
              />
              <fa-icon class="absolute p-2.5 leading-5 top-0 right-4 text-gray-400" *ngIf="!isRepeatPasswordVisible" [icon]="faEyeSlash" (click)="toggleRepeatPassword()"></fa-icon>
              <fa-icon class="absolute p-2.5 leading-5 top-0 right-4 text-gray-400" *ngIf="isRepeatPasswordVisible" [icon]="faEye" (click)="toggleRepeatPassword()"></fa-icon>
            </div>
            <div class="col-span-12 md:col-span-2"></div>
            <div
              *ngIf="credentialForm.controls['repeatPassword'].invalid && (credentialForm.controls['repeatPassword'].touched)"
              class="text-red-600 col-span-12 md:col-span-10 text-xs mt-1"
            >
              <div *ngIf="credentialForm.controls['repeatPassword'].errors?.['required']">
                {{ 'Repeat password is required.' | translate }}
              </div>
              <div *ngIf="credentialForm.controls['repeatPassword'].errors?.['match']">
                {{ 'The repeat password does not match.' | translate }}
              </div>
            </div>
          </div>

          <hr class="mb-4"/>

          <!-- Company -->
          <div class="grid grid-cols-12">
            <div class="col-span-12 md:col-span-2">
              <label
                for="email"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white my-2"
              ><span class="text-base font-medium text-green-900 dark:text-white">{{ 'Company' | translate }}</span> <span class="text-red-500" *ngIf="!credentialForm.controls['noCompanyInfo'].value">*</span></label
              >
            </div>
            <div class="col-span-12 md:col-span-10 grid grid-cols-12">
              <div class="col-span-12 pb-2 md:col-span-6 md:pr-2 md:pb-0 mb-2">
                <span class="p-float-label relative">
                  <input
                    pInputText
                    name="companyName"
                    id="companyName"
                    class=" border border-gray-300 text-gray-900 text-sm rounded-3xl focus:ring-green-600 focus:border-green-600 focus:outline-green-600 block w-full p-2.5 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 disabled:bg-gray-100"
                    placeholder=""
                    formControlName="companyName"
                    [disabled]="noCompanyInfo"
                    [attr.disabled]="disableUserInfo ? '' : null"

                  />
                  <label
                    for="companyName"
                    class=""
                  >{{ 'Company Name' | translate }}</label
                  >
                </span>
                <div
                  *ngIf="credentialForm.controls['companyName'].invalid && (credentialForm.controls['companyName'].dirty || credentialForm.controls['companyName'].touched)"
                  class="text-red-600 col-span-10 text-xs mt-1"
                >
                  <div *ngIf="credentialForm.controls['companyName'].errors?.['required']">
                    {{ 'Company name is required.' | translate }}
                  </div>
                </div>
              </div>
              <div class="col-span-12 pb-2 md:col-span-6 md:pr-2 md:pb-0 mb-2">
                <select name="companyInd" formControlName="companyInd"
                        class="leading-5 border border-gray-300 rounded-3xl focus:outline-none focus:ring-green-600 focus:border-green-600 block w-full p-2.5 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 disabled:bg-gray-100"
                        [class]="credentialForm.value['companyInd'] == '' ? 'text-gray-500' : 'text-gray-900'"
                        [disabled]="noCompanyInfo"
                        [attr.disabled]="disableUserInfo ? '' : null">
                  <option value="" disabled selected>{{ 'Select Industry...' | translate }}</option>
                  <option value="Academic Institution">{{ 'Academic Institution' | translate }}</option>
                  <option value="Consultant">{{ 'Consultant' | translate }}</option>
                  <option value="Contractor">{{ 'Contractor' | translate }}</option>
                  <option value="Freelance">{{ 'Freelance' | translate }}</option>
                  <option value="Government">{{ 'Government' | translate }}</option>
                  <option value="Manufacturer/Supplier/Startup">{{ 'Manufacturer/Supplier/Startup' | translate }}</option>
                  <option value="Professional Body">{{ 'Professional Body' | translate }}</option>
                  <option value="Project Client">{{ 'Project Client (Developer/Estate Officer/NGO)' | translate }}</option>
                  <option value="Property Management Company">{{ 'Property Management Company' | translate }}</option>
                  <option value="Research Body">{{ 'Research Body' | translate }}</option>
                  <option value="Statutory Body">{{ 'Statutory Body' | translate }}</option>
                  <option value="Trade Association">{{ 'Trade Association' | translate }}</option>
                  <option value="Utility Organisation">{{ 'Utility Organisation' | translate }}</option>
                  <option value="Others">{{ 'Others' | translate }}</option>
                </select>
                <div
                  *ngIf="credentialForm.controls['companyInd'].invalid && (credentialForm.controls['companyInd'].dirty || credentialForm.controls['companyInd'].touched)"
                  class="text-red-600 col-span-10 text-xs mt-1"
                >
                  <div *ngIf="credentialForm.controls['companyInd'].errors?.['required']">
                    {{ 'Company industry is required.' | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-12">
            <div class="col-span-12 md:col-span-2">
            </div>
            <div class="col-span-12 md:col-span-10 grid grid-cols-12">
              <div class="col-span-12 pb-2 md:col-span-6 md:pr-2 md:pb-0">
                <span class="p-float-label relative">
                  <input
                    pInputText
                    name="jobTitle"
                    id="jobTitle"
                    class=" border border-gray-300 text-gray-900 text-sm rounded-3xl focus:ring-green-600 focus:border-green-600 focus:outline-green-600 block w-full p-2.5 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 disabled:bg-gray-100"
                    placeholder=""
                    formControlName="jobTitle"
                    [disabled]="noCompanyInfo"
                    [attr.disabled]="disableUserInfo ? '' : null"
                  />
                  <label
                    for="lastName"
                    class=""
                  >{{ 'Job Title' | translate }}</label
                  >
                </span>
                <div
                  *ngIf="credentialForm.controls['jobTitle'].invalid && (credentialForm.controls['jobTitle'].dirty || credentialForm.controls['jobTitle'].touched)"
                  class="text-red-600 col-span-10 text-xs mt-1"
                >
                  <div *ngIf="credentialForm.controls['jobTitle'].errors?.['required']">
                    {{ 'Job title is required.' | translate }}
                  </div>
                </div>
              </div>
              <div class="col-span-12 pb-2 md:col-span-6 md:pr-2 md:pb-0">
              </div>
            </div>
          </div>
          <div class="grid grid-cols-12">
            <div class="col-span-12 md:col-span-2">
            </div>
            <div class="col-span-12 md:col-span-10 grid grid-cols-12 mt-2">
              <div class="col-span-12 pb-2 md:col-span-6 md:pr-2 md:pb-0 cursor-pointer" (click)="onNoCompanyInfoChange()">
                <input type="checkbox" formControlName="noCompanyInfo" name="noCompanyInfo" (change)="onNoCompanyInfoChange($event)" class="rounded checked:bg-green-600 checked:hover:bg-green-600 checked:active:bg-green-600 checked:focus:bg-green-600 focus:outline-none focus:ring-green-600"
                       [attr.disabled]="disableUserInfo ? '' : null"/>
                <span class="text-sm text-gray-600 ml-2">{{ 'Prefer not to provide now' | translate }}</span>
              </div>
              <div class="col-span-12 pb-2 md:col-span-6 md:pr-2 md:pb-0">
              </div>
            </div>
          </div>

          <hr class="my-4"/>
          <div class="mt-6 flex flex-row justify-center md:justify-start">
            <div class="flex-grow"></div>
            <button
              type="submit"
              class="flex flex-row justify-center w-40 text-white bg-notus-primary-600 hover:bg-notus-primary-700 focus:outline-none focus:ring-notus-primary-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 items-center"
              *ngIf="!isLoading && !disableUserInfo"
              (click)="mode =='register' ? onRegisterWithEmail() : onUpdateProfile()"
            >
              <ng-container *ngIf="mode == 'register'">
                {{ 'Next' | translate }}
              </ng-container>
              <ng-container *ngIf="mode == 'profile'">
                {{ 'Save' | translate }}
              </ng-container>
            </button>
            <button
              type="submit"
              disabled
              class="flex flex-row justify-center w-40 text-white bg-gray-300 focus:outline-none focus:ring-notus-primary-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-white"
              *ngIf="isLoading"
            >
              <flowbite-loading size="1.25rem"></flowbite-loading>
            </button>
          </div>
        </div>
      </div>
      <div
        *ngIf="isTandC"
        class="switch-page w-full max-w-3xl md:mt-0 xl:p-0 mr-auto"
        [class]="currentStep == 2 ? 'active' : currentStep > 2 ? 'past' : 'future'"
      >
        <div class="sm:px-4" [class]="pv ? '' : 'p-0 sm:p-0 sm:px-0'">
          <div class="col-span-12 md:col-span-10 grid grid-cols-12">
            <div class="col-span-12 md:col-span-6 md:pr-2 md:pb-0 flex flex-row space-x-2">
              <!-- <fa-icon class="text-green-600 cursor-pointer" [icon]="faBack" (click)="onRegisterStep2Back()"></fa-icon> -->
              <label
                for="email"
                class="block mb-2 font-medium text-gray-900 dark:text-white"
              ><span class="text-xl font-medium text-green-900 dark:text-white">{{ 'Disclaimer' | translate }}</span></label
              >
            </div>
          </div>

          <div class="text-gray-600">
            <div class="h-[calc(100vh-25rem)] overflow-y-scroll border p-2" (scroll)="onTandc1Scroll($event)">
              <ng-container *ngIf="translate.currentLang == 'en'">
                <br/>
                PLEASE READ THE FOLLOWING TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS WEBSITE. BY USING THIS WEBSITE YOU AGREE TO ABIDE BY THE TERMS AND CONDITIONS SET OUT BELOW.​<br/>
                <br/>
                <strong>COPYRIGHT NOTICE</strong><br/>
                The content available on this Construction Industry Council (CIC) website, including but not limited to all text, graphics, drawings, diagrams, photographs and compilation of data or other materials are subject to copyright owned by CIC or other entities. Except as expressly permitted herein or where prior written authorization is obtained from CIC, any reproduction, adaptation, distribution, dissemination or making available of such copyright works to the public is strictly prohibited.<br/>
                <br/>
                Permission is granted for users to download the materials herein to store them in local computers, provided that this is solely for personal or non-commercial internal use, and provided further that this copyright notice is downloaded at the same time. Users should note that this permission only applies to CIC copyright materials. Where third party copyrights are involved, an appropriate notice will appear in this website.<br/>
                <br/>
                <strong>DISCLAIMER</strong><br/>
                The information contained in this website is compiled by the Construction Industry Council (CIC) for general information only. Whilst CIC endeavours to ensure the accuracy of this general information, no statement, representation, warranty or guarantee, express or implied, is given as to its accuracy or appropriateness for use in any particular circumstances.<br/>
                <br/>
                CIC website and material relating to information, products and services (or to third party information, products and services), is provided 'as is', without any representation or endorsement made and without warranty of any kind whether express or implied, including, but not limited to, the implied warranties of satisfactory quality, fitness for a particular purpose, non-infringement, compatibility, security, completeness and accuracy.<br/>
                <br/>
                In no event will CIC be liable for any expense, loss or damage including, without limitation, indirect or consequential loss or damage, or any expense, loss or damage whatsoever arising from use, or loss of use, of data, or loss of profits, arising out of or in connection with the use of this website, or reliance on its content.<br/>
                <br/>
                No data transmission over the internet can be guaranteed as totally secure. Whilst CIC strives to protect such information, CIC does not warrant and cannot ensure the security of any information which you transmit to us.<br/>
                <br/>
                CIC website is not a substitute for independent professional advice and users should obtain any appropriate professional advice relevant to their particular circumstances.<br/>
                <br/>
                This website can also contain information contributed by others over whom, and in respect of which, CIC may have no influence. Users may link from this website to sites provided by others. CIC expressly states that it has not approved or endorsed the information provided by others on this website or any other sites linked to this website and CIC accepts no responsibility or liability (howsoever caused) for such information.<br/>
                <br/>
                <strong>COLLECTION OF INFORMATION</strong><br/>
                The CIC will record the visits to CIC Website by using cookies and page tagging without collecting any personal identifiable information of users.<br/>
                <br/>
                Cookies<br/>
                A cookie is a small amount of data created in a computer when a person visits a website on the computer. It often includes an anonymous unique identifier. A cookie can be used to identify a computer. It, however, is not used to collect any personal information. In other words, it does not have the function of identifying an individual user of the website.<br/>
                <br/>
                Cookies are used by the CIC to collect statistics about the number of visits of users to CIC Website and the users’ preference of websites and online services offered on CIC Website.<br/>
                <br/>
                You may choose to accept or reject cookies. If you reject the cookies, you will not be able to use some of the functions of CIC Website, such as saving your preferences in using the CIC Website and accessing some online services on CIC Website.<br/>
                <br/>
                Page tagging<br/>
                Javascript and pixel tags are used to collect statistics on customer usage patterns of CIC Website and online services offered on CIC Website; and for tracking the performance of our online advertisements. A pixel tag is a transparent image placed on certain webpages to collect data on user activities. The collected data are aggregated and analysed for measuring the effectiveness, improving the usability of CIC Website and the services / online advertisements provided on CIC Website. No personal or identifiable information about a visitor would be collected.<br/>
                <br/>
                You may disable JavaScript on your computer. Disabling JavaScript should not affect your access to CIC Website, but you will not be able to use some of the CIC Website functions, such as changing text size and accessing CIC online services offered through CIC Website.<br/>
                <br/>
                <strong>MEMBER LOGIN</strong><br/>
                Please note that submission of information over the internet may be subject to interruption, transmission blackout, delayed transmission due to internet traffic, or incorrect data transmission due to the public nature of the Internet. The Council cannot assume responsibility for malfunctions in communications facilities not under our control that may affect the accuracy or timeliness of messages you send.<br/>
                <br/>
              </ng-container>
              <ng-container *ngIf="translate.currentLang == 'zh-Hans'">
                <br/>
                在使用本网站前，请仔细阅读以下的条款及条件。如阁下使用本网站，即表示阁下同意遵守下列条款及条件。<br/>
                <br/>
                <strong>版权公告</strong><br/>
                本建造业议会（议会）网站的内容（包括但不限于所有文字、绘图、图画、图解、照片，亦包括但不限于数据或其他材料的汇编），均受到议会或其他实体所拥有的版权规限。除了在本网站内获明文准许或事先得到议会的书面授权外，严禁将该等版权作品复制、改编、分发、散布，或提供予公众。<br/>
                <br/>
                现准许使用者下载本网站的材料，以供贮存于其个人使用的电脑内，但仅限于作个人用途或非商业性质的内部用途，并须同时下载本版权公告。使用者应注意，上述准许只适用于议会版权材料。当涉及第三者版权时，适当的公告会在本网站出现。<br/>
                <br/>
                <strong>免责声明</strong><br/>
                本网站载列的资料由建造业议会（议会）编制，只供一般参考。议会虽已尽力确保该等资料准确，但对于该等资料在任何特定情况下使用时的准确性或恰当性，并没有作出任何明示或隐含的陈述、申述、保证或担保。<br/>
                <br/>
                议会网站及有关资讯、产品和服务的材料（或有关第三者的资讯、产品和服务的材料），均以当前展示的形式提供，概无作出任何陈述或认可证明，亦概无作出任何类型、不论明示或隐含的担保，包括但不限于提供满意的质素、切合特定的目的、非侵权、兼容性、保安、完整性及准确性的隐含保证。<br/>
                <br/>
                不论任何情况下，议会将不会就任何支出、损失或损毁，包括但不限于间接或相应而生的损失或损毁，或任何由使用数据或不使用数据而招致的任何支出、损失或损毁，或由使用本网站而导致的或与使用本网站有关的利润损失，或依赖有关内容而负上任何法律责任。<br/>
                <br/>
                透过互联网作出的数据传输并不能担保绝对保密。纵使议会致力保护有关资料，惟议会并不担保，亦不能保证阁下传送予议会的任何资料的保密性。<br/>
                <br/>
                议会网站并非独立专业意见的代替品，各使用者应该在适用其特定情况下获取任何适当的专业意见。<br/>
                <br/>
                本网站亦可能载有由其他人士或机构所提供的资料，而议会对该等人士或机构以及对该等资料并无影响力。使用者可从本网站连结至由其他人士或机构所提供的网站。议会现明文述明，议会并没有核准或认可由其他人士或机构在本网站或与本网站连结的其他网站上所提供的资料，对于该等资料亦不承担任何责任或法律责任（不论该等责任是如何导致的）。<br/>
                <br/>
                <strong>收集资料</strong><br/>
                小型文字档案<br/>
                当使用者用电脑浏览一个网站时，小型文字档案会建立于电脑内，这种档案通常包含匿名而独有的识别符，用以辨识个别电脑，但不能用以收集任何个人资料，即是说，不具备辨识网站个别使用者的功能。<br/>
                <br/>
                议会使用小型文字档案收集的议会网站统计资料包括浏览人次、使用者对网站及议会网站提供的网上服务的喜好。<br/>
                <br/>
                你可选择接受或拒绝贮存小型文字档案。如你拒绝贮存小型文字档案，将不能使用议会网站的某些功能，例如贮存你使用议会网站浏览喜好及登入议会网站的某些网上服务。<br/>
                <br/>
                网站信标<br/>
                议会使用JavaScript 及 像素标籤（pixel tags）收集客户的议会网站使用模式和议会网站议会网上服务的使用模式等统计资料，以及追踪议会网上广告的成效。像素标籤是放在特定网页上的透明图档，用以收集浏览数据。收集得来的数据经整合及分析后，会用作量度成效，用以改善议会网站的易用性及议会网站提供的服务／网上广告，但不会用以收集个人资料或足以辨识个别使用者的资料。<br/>
                <br/>
                你可关闭电脑上的JavaScript，关闭JavaScript不会影响你登入议会网站，但你将不能使用议会网站某些功能，例如改变文字大小和透过议会网站登入议会的网上服务。<br/>
                <br/>
                <strong>成员登入</strong><br/>
                注意︰基于互联网的运作性质，经互联网提交的资料可能受到干扰、传送中断、传送延误或传送错误资料。在建造业议会控制范围以外的通讯设施发生任何故障，影响阁下传送信息的准确性或时效，建造业议会概不负责。<br/>
                <br/>
              </ng-container>
              <ng-container *ngIf="translate.currentLang != 'en' && translate.currentLang != 'zh-Hans'">
                <br/>
                在使用本網站前，請仔細閱讀以下的條款及條件。如閣下使用本網站，即表示閣下同意遵守下列條款及條件。<br/>
                <br/>
                <strong>版權公告</strong><br/>
                本建造業議會（議會）網站的內容（包括但不限於所有文字、繪圖、圖畫、圖解、照片，亦包括但不限於數據或其他材料的匯編），均受到議會或其他實體所擁有的版權規限。除了在本網站內獲明文准許或事先得到議會的書面授權外，嚴禁將該等版權作品複製、改編、分發、散布，或提供予公眾。<br/>
                <br/>
                現准許使用者下載本網站的材料，以供貯存於其個人使用的電腦內，但僅限於作個人用途或非商業性質的內部用途，並須同時下載本版權公告。使用者應注意，上述准許只適用於議會版權材料。當涉及第三者版權時，適當的公告會在本網站出現。<br/>
                <br/>
                <strong>免責聲明</strong><br/>
                本網站載列的資料由建造業議會（議會）編製，只供一般參考。議會雖已盡力確保該等資料準確，但對於該等資料在任何特定情況下使用時的準確性或恰當性，並沒有作出任何明示或隱含的陳述、申述、保證或擔保。<br/>
                <br/>
                議會網站及有關資訊、產品和服務的材料（或有關第三者的資訊、產品和服務的材料），均以當前展示的形式提供，概無作出任何陳述或認可證明，亦概無作出任何類型、不論明示或隱含的擔保，包括但不限於提供滿意的質素、切合特定的目的、非侵權、兼容性、保安、完整性及準確性的隱含保證。<br/>
                <br/>
                不論任何情況下，議會將不會就任何支出、損失或損毀，包括但不限於間接或相應而生的損失或損毀，或任何由使用數據或不使用數據而招致的任何支出、損失或損毀，或由使用本網站而導致的或與使用本網站有關的利潤損失，或依賴有關內容而負上任何法律責任。<br/>
                <br/>
                透過互聯網作出的數據傳輸並不能擔保絕對保密。縱使議會致力保護有關資料，惟議會並不擔保，亦不能保證閣下傳送予議會的任何資料的保密性。<br/>
                <br/>
                議會網站並非獨立專業意見的代替品，各使用者應該在適用其特定情況下獲取任何適當的專業意見。<br/>
                <br/>
                本網站亦可能載有由其他人士或機構所提供的資料，而議會對該等人士或機構以及對該等資料並無影響力。使用者可從本網站連結至由其他人士或機構所提供的網站。議會現明文述明，議會並沒有核准或認可由其他人士或機構在本網站或與本網站連結的其他網站上所提供的資料，對於該等資料亦不承擔任何責任或法律責任（不論該等責任是如何導致的）。<br/>
                <br/>
                <strong>收集資料</strong><br/>
                小型文字檔案<br/>
                當使用者用電腦瀏覽一個網站時，小型文字檔案會建立於電腦內，這種檔案通常包含匿名而獨有的識別符，用以辨識個別電腦，但不能用以收集任何個人資料，即是說，不具備辨識網站個別使用者的功能。<br/>
                <br/>
                議會使用小型文字檔案收集的議會網站統計資料包括瀏覽人次、使用者對網站及議會網站提供的網上服務的喜好。<br/>
                <br/>
                你可選擇接受或拒絕貯存小型文字檔案。如你拒絕貯存小型文字檔案，將不能使用議會網站的某些功能，例如貯存你使用議會網站瀏覽喜好及登入議會網站的某些網上服務。<br/>
                <br/>
                網站信標<br/>
                議會使用JavaScript 及 像素標籤（pixel tags）收集客戶的議會網站使用模式和議會網站議會網上服務的使用模式等統計資料，以及追蹤議會網上廣告的成效。像素標籤是放在特定網頁上的透明圖檔，用以收集瀏覽數據。收集得來的數據經整合及分析後，會用作量度成效，用以改善議會網站的易用性及議會網站提供的服務／網上廣告，但不會用以收集個人資料或足以辨識個別使用者的資料。<br/>
                <br/>
                你可關閉電腦上的JavaScript，關閉JavaScript不會影響你登入議會網站，但你將不能使用議會網站某些功能，例如改變文字大小和透過議會網站登入議會的網上服務。<br/>
                <br/>
                <strong>成員登入</strong><br/>
                注意︰基於互聯網的運作性質，經互聯網提交的資料可能受到干擾、傳送中斷、傳送延誤或傳送錯誤資料。在建造業議會控制範圍以外的通訊設施發生任何故障，影響閣下傳送信息的準確性或時效，建造業議會概不負責。<br/>
                <br/>
              </ng-container>
            </div>
            <div
              *ngIf="isTandc1Error && !isTandc1Read"
              class="text-red-600 col-span-10 mt-2"
            >
              {{ 'Please read the Personal Information Agreement to the end.' | translate }}
            </div>
            <!-- <div class="mt-2">
<input type="checkbox" name="tandc1" (change)="onTandc1Change($event)" class="rounded checked:bg-green-600 checked:hover:bg-green-600 checked:active:bg-green-600 checked:focus:bg-green-600 focus:outline-none focus:ring-green-600" /><span class="text-red-500 ml-1">*</span>
<span class="text-xs text-gray-600 ml-2">{{ 'I have read and understand the Personal Information Agreement' | translate }}</span>
</div>
<div
*ngIf="!isTandc1 && isSubmitted"
class="text-red-600 col-span-10 text-xs mt-1"
>
{{ 'Please agree with the Personal Information Agreement.' | translate }}
            </div> -->
            <div class="mt-6 flex flex-row justify-center md:justify-start">
              <button
                class="flex flex-row justify-center w-40 text-white bg-notus-primary-600 hover:bg-notus-primary-700 focus:outline-none focus:ring-notus-primary-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 items-center"
                *ngIf="!isLoading"
                (click)="onRegisterStep2Back()"
              >
                {{ 'Back' | translate }}
              </button>
              <div class="flex-grow"></div>
              <button
                class="flex flex-row justify-center w-40 text-white bg-notus-primary-600 hover:bg-notus-primary-700 focus:outline-none focus:ring-notus-primary-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 items-center"
                *ngIf="!isLoading && !disableUserInfo"
                (click)="onRegisterStep2Confirm()"
              >
                {{ 'Agree' | translate }}
              </button>
              <button
                type="submit"
                disabled
                class="flex flex-row justify-center w-40 text-white bg-gray-300 focus:outline-none focus:ring-notus-primary-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-white"
                *ngIf="isLoading"
              >
                <flowbite-loading size="1.25rem"></flowbite-loading>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="isTandC"
        class="switch-page w-full max-w-3xl md:mt-0 xl:p-0 mr-auto"
        [class]="currentStep == 3 ? 'active' : currentStep > 3 ? 'past' : 'future'"
      >
        <div class="sm:px-4" [class]="pv ? '' : 'p-0 sm:p-0 sm:px-0'">
          <div class="col-span-12 md:col-span-10 grid grid-cols-12">
            <div class="col-span-12 md:col-span-6 md:pr-2 md:pb-0 flex flex-row space-x-2">
              <!-- <fa-icon class="text-green-600 cursor-pointer" [icon]="faBack" (click)="onRegisterStep3Back()"></fa-icon> -->
              <label
                for="email"
                class="block mb-2 font-medium text-gray-900 dark:text-white"
              ><span class="text-xl font-medium text-green-900 dark:text-white">{{ 'Privacy Policy Statement' | translate }}</span></label
              >
            </div>
          </div>
          <div class="text-gray-600">

            <div class="h-[calc(100vh-27rem)] overflow-y-scroll border p-2" (scroll)="onTandc2Scroll($event)">
              <ng-container *ngIf="translate.currentLang == 'en'">
                <br/>
                <strong>1. Pledge</strong><br/>
                Every member of the Construction Industry Council, including the Hong Kong Institute of Construction, Zero Carbon Building, Construction Sector Imported Labour Quarters Limited, and its affiliated or related companies (the “CIC”) takes the protection of your personal data very seriously. We respect personal data privacy and are committed to fully implementing and complying with the data protection principles and all relevant provisions of the Personal Data (Privacy) Ordinance (“PDPO”).<br/>
                <br/>
                This <strong>Privacy Policy Statement</strong> outlines how we collect, use, manage and protect the personal data that we may collect from or about you. It applies to all individuals whose personal data may be handled, whether as a data user or processor, by the CIC.<br/>
                <br/>
                <strong>2. Collection of Personal Data</strong><br/>
                You may be invited by the CIC to provide your personal data, on a voluntary basis, on the CIC Website or in application forms for specific purposes at different times. Under certain circumstance of which your provision of personal data is mandatory (e.g. construction workers registration), we shall inform you of the consequences of not providing the same. If you are under the age of eighteen, you should obtain consent from your parent or guardian before you provide the CIC with your personal data.<br/>
                <br/>
                The CIC will use your personal data for specific purposes identified in the <strong>Personal Information Collection Statement</strong> provided to you on or before your personal data is collected.<br/>
                <br/>
                <strong>3. Types of Personal Data Collected</strong><br/>
                Depending on the specific purpose(s), you may be required to provide the CIC with your personal data, including but not limited to your name, gender, age (or age range), date of birth, contact details, email/mailing/residential/correspondence address, contact number, membership number, educational level, occupation, username, password and tracking data, etc.<br/>
                <br/>
                <strong>4. Purposes of Use</strong><br/>
                The CIC may collect your personal data for specific purposes in the following situations (non-exhaustive):<br/>
                <br/>
                ．for registration as construction workers;<br/>
                ．for enrolment on education courses/publicity programmes (to carry out internal matching procedures whenever necessary) and acquisitions/subscriptions of other different types of CIC’s services;<br/>
                ．for employment-related and human resources management purposes throughout the process from recruitment to post-employment;<br/>
                ．for direct marketing purposes after your consent has been obtained;<br/>
                ．for handling of enquiries and complaints from various stakeholders;<br/>
                ．for conducting surveys, researches or analyses to improve our statutory functions;<br/>
                ．for complying with applicable laws in or outside Hong Kong as may be required by applicable government authorities, courts, law enforcement, regulatory, public or statutory bodies;<br/>
                ．performing our contractual duties; and<br/>
                ．for any other purposes to perform our statutory functions.<br/>
                <br/>
                If the ultimate purposes of use are not directly related to the intended collection purpose, we would obtain prescribed consent from you in accordance with the PDPO.<br/>
                <br/>
                <strong>5. Collection of Information via Cookies</strong><br/>
                Cookies, which can identify a device but not the identity of individual users of the website, are used by the CIC to collect statistics about the number of visits of users to the CIC Website, the users’ preference of websites and online services offered on the CIC Website.<br/>
                <br/>
                You may choose to accept or reject cookies. If you reject the cookies, you will not be able to use some of the functions of the CIC Website, such as saving your preferences in using the CIC Website and accessing some online services on the CIC Website.<br/>
                <br/>
                <strong>6. Outsourcing Arrangements</strong><br/>
                To serve any specific purposes, your personal data may be transferred to and stored in the following third parties (or any of them) on behalf of the CIC, whether in or outside of Hong Kong:<br/>
                <br/>
                ．any or all of the CIC’s affiliates and/or subsidiaries;<br/>
                ．any third party service providers, contractors/sub-contractors that, on behalf of the CIC, operate or maintain membership, event registration, tour booking, researches and/or analyses, or carry out back-end services, administrative services, verification services, cloud services or information technology services, or provide necessary support or services to the CIC to enable us to provide our services, including any insurance, banking or third party payment gateway services used by the CIC, and any other entities that discharge contractual obligations on our behalf;<br/>
                ．any of the CIC’s professional advisors, including but not limited to lawyers, accountants and auditors; or<br/>
                ．any party that owes a duty of confidentiality to the CIC.<br/>
                <br/>
                The CIC will adopt stringent measures to safeguard your personal data entrusted to such third-party service providers, which include obligations in relation to the confidentiality, security, and lawful processing of any personal data we share with them.<br/>
                <br/>
                <strong>7. Retention</strong><br/>
                The CIC will retain your personal data for a period not longer than necessary for the fulfilment of the purposes for which they are to be used. Retention periods for personal data may vary subject to business needs or legal requirements.<br/>
                <br/>
                We execute our policy on personal data retention and review it from time to time. Personal data will be timely purged accordingly.<br/>
                <br/>
                <strong>8. Security and Storage</strong><br/>
                The physical records of your personal data will be stored in secured places with restricted access by us and those with whom we share your personal data in accordance with this Privacy Policy Statement and the Personal Information Collection Statement. The electronic records of your personal data will be stored in the servers of the CIC and/or relevant CIC service providers (whether in or outside Hong Kong) located in secured places and protected by adequate IT security measures (including but not limited to measures of encryption and access control).<br/>
                <br/>
                The CIC Website(s) uses TLS protocol to encrypt data during network transmission to protect your personal data. All personal data you provide to the CIC via the CIC Website(s) are secured, and access to them is restricted to authorised personnel only.<br/>
                <br/>
                <strong>9. Data Access and Correction</strong><br/>
                In accordance with the PDPO, your right to access personal data can be exercised by completing the <a href="https://www.pcpd.org.hk/english/publications/files/Dforme.pdf" target="_blank" rel="noopener">Data Access Request Form</a>. The CIC may apply an administrative charge for providing you with access to your personal data in response to such request. You may also request for making necessary correction to the data subsequent to data access request.<br/>
                <br/>
                Requests for the above should be addressed to:<br/>
                <br/>
                Officer-in-charge (Data Access Request)<br/>
                Construction Industry Council<br/>
                38/F, COS Centre<br/>
                56 Tsun Yip Street<br/>
                Kwun Tong, Kowloon<br/>
                <br/>
                Or via email at <a href="mailto:enquiry@cic.hk" target="_blank" rel="noopener">enquiry@cic.hk</a>.<br/>
                <br/>
                <strong>10. Enquiries</strong><br/>
                Any enquiries regarding our Privacy Policy Statement may be made to the above correspondence address or via email at <a href="mailto:enquiry@cic.hk" target="_blank" rel="noopener">enquiry@cic.hk</a>.<br/>
                <br/>
              </ng-container>
              <ng-container *ngIf="translate.currentLang == 'zh-Hans'">
                <br/>
                <strong>1. 承诺</strong><br/>
                建造业议会的每位成员，包括香港建造学院、建造业零碳天地、建造业输入劳工宿舍有限公司，以及其附属或关联公司（「议会」），都非常重视保护你的个人资料。我们尊重个人资料私隐，并承诺全面执行及遵守《个人资料（私隐）条例》的所有相关条文和保障资料原则。<br/>
                <br/>
                本<strong>私隐政策声明</strong>概述我们如何收集、使用、管理和保护我们可能从你收集的或不从你收集但关于你的个人资料。它适用于可能由议会，无论是作为资料使用者还是处理者，处理的所有个别人士的个人资料。<br/>
                <br/>
                <strong>2. 个人资料的收集</strong><br/>
                议会可能会于不同时间邀请你，自愿地在议会网站或以申请表格提供你的个人资料作特定用途。在某些情况下，你必须提供个人资料（例如建造业工人注册），我们会告知你不提供该等个人资料的后果。如果你未满十八岁，你应在向议会提供个人资料之前获得父母或监护人的同意。<br/>
                <br/>
                议会会将你的个人资料用于在收集你的个人资料时或之前，向你提供的<strong>收集个人资料声明</strong>中指定的特定目的。<br/>
                <br/>
                <strong>3. 收集的个人资料类别</strong><br/>
                视乎具体目的，你或会被议会邀请你提供个人资料，包括但不限于你的姓名、性别、年龄（或年龄范围）、出生日期、联络资料、电邮／邮递／住所／通讯地址、联络电话、会员号码、教育程度、职业、用户名称、密码及追踪资料等。<br/>
                <br/>
                <strong>4. 使用目的</strong><br/>
                在下述情况中，议会可能会收集你的个人资料作特定用途（并非详尽无遗）：<br/>
                <br/>
                ．建造业工人注册；<br/>
                ．报读教育课程／宣传计划（如有需要时进行内部配对程式）及购买／认购议会其他不同类型的服务；<br/>
                ．在由招聘到离职后的过程中用于与就业相关及人力资源管理目的；<br/>
                ．在获得你的同意后用于直接促销目的；<br/>
                ．处理各持份者的查询及投诉；<br/>
                ．进行调查、研究或分析，以改善我们的法定职能；<br/>
                ．遵守适用于香港境内或境外的法律要求，可能由政府当局、法院、执法部门、监管机构、公营机构或法定机构提出的要求；<br/>
                ．履行我们在合约上的职责； 及<br/>
                ．用于履行我们的法定职能的任何其他目的。<br/>
                <br/>
                如最终使用目的与预期收集目的没有直接关系，我们会根据《个人资料（私隐）条例》取得你的订明同意。<br/>
                <br/>
                <strong>5. 透过 Cookies 收集资讯</strong><br/>
                议会使用Cookies（可识别设备，但不能识别网站个别使用者的身份）收集有关网站使用者浏览议会网站的次数、使用者对网站偏好及议会网站提供的在线服务等的统计。<br/>
                <br/>
                你可以选择接受或拒绝 Cookies。如果你拒绝Cookies，你将无法使用议会网站的某些功能，例如储存你在使用议会网站及登入议会网站一些网上服务的偏好。<br/>
                <br/>
                <strong>6. 外判安排</strong><br/>
                为了任何特定用途，你的个人资料可能会被议会转移及储存于下述第三方（或其中任何一方），不论在香港境内或境外：<br/>
                <br/>
                ．任何或所有议会的关联公司及／或附属公司；<br/>
                ．任何代表议会营运或维持会员资格、活动注册、导览预订、研究及／或分析或代表议会进行后端服务、行政服务、验证服务、云端服务或信息技术服务的第三方服务供应商、承包商或分包商，或向议会提供必要的支援或服务，包括保险、银行或议会使用的任何第三方支付网关服务，以便我们提供服务，以及代表议会履行合约义务之实体；<br/>
                ．议会的专业顾问，包括但不限于律师、会计师和审计师; 或<br/>
                ．任何对议会负有保密责任的一方。<br/>
                <br/>
                议会将采取严格措施，保护委托予该等第三方服务供应商的个人资料，包括议会与他们分享的任何个人资料的保密性、安全性和合法处理的有关义务。<br/>
                <br/>
                <strong>7. 保留</strong><br/>
                议会将保留你的个人资料，保留时间并不会超过其使用目的所需的时间。个人资料的保留期限可能因业务需要或法律要求而异。<br/>
                <br/>
                我们执行保留个人资料的政策，并不时对该政策进行检视；个人资料将按政策适时销毁。<br/>
                <br/>
                <strong>8. 保安和储存</strong><br/>
                根据本私隐政策声明及收集个人资料声明，载有你个人资料的文件纪录将储存在安全的地方，我们及与我们分享你个人资料的人士均被限制查阅有关资料。载有你个人资料的电子纪录将储存于议会及／或相关议会服务供应商置于安全处所的伺服器（不论在香港境内或境外），并受适当的资讯科技保安措施（包括但不限于加密及登入管制措施）保护。<br/>
                <br/>
                议会网站在网路传输过程中使用TLS协定加密数据，以保护你的个人资料。你透过议会网站向议会提供的所有个人资料均受到保护，只有获授权人员才能查阅。<br/>
                <br/>
                <strong>9. 资料查阅和更正</strong><br/>
                根据《个人资料（私隐）条例》，你可填妥「<a href="https://www.pcpd.org.hk/chinese/publications/files/Dformc.pdf" target="_blank" rel="noopener">查阅资料申请表</a>」行使查阅个人资料的权利。议会可能会就向你提供要求查阅的个人资料而收取行政费用。你亦可在查阅资料后要求对该等个人资料作出更正。<br/>
                <br/>
                上述要求应发送至：<br/>
                <br/>
                主管（查阅资料要求）<br/>
                建造业议会<br/>
                九龙观塘骏业街56号<br/>
                中海日升中心38楼<br/>
                <br/>
                或电邮至 <a href="mailto:enquiry@cic.hk" target="_blank" rel="noopener">enquiry@cic.hk</a><br/>
                <br/>
                <strong>10. 查询</strong><br/>
                如欲查询本私隐政策声明，可透过上述通讯地址或电邮至 <a href="mailto:enquiry@cic.hk" target="_blank" rel="noopener">enquiry@cic.hk</a>。<br/>
                <br/>
              </ng-container>
              <ng-container *ngIf="translate.currentLang != 'en' && translate.currentLang != 'zh-Hans'">
                <br/>
                <strong>1. 承諾</strong><br/>
                建造業議會的每位成員，包括香港建造學院、建造業零碳天地、建造業輸入勞工宿舍有限公司，以及其附屬或關聯公司（「議會」），都非常重視保護你的個人資料。我們尊重個人資料私隱，並承諾全面執行及遵守《個人資料（私隱）條例》的所有相關條文和保障資料原則。<br/>
                <br/>
                本<strong>私隱政策聲明</strong>概述我們如何收集、使用、管理和保護我們可能從你收集的或不從你收集但關於你的個人資料。它適用於可能由議會，無論是作為資料使用者還是處理者，處理的所有個別人士的個人資料。<br/>
                <br/>
                <strong>2. 個人資料的收集</strong><br/>
                議會可能會於不同時間邀請你，自願地在議會網站或以申請表格提供你的個人資料作特定用途。在某些情況下，你必須提供個人資料（例如建造業工人註冊），我們會告知你不提供該等個人資料的後果。如果你未滿十八歲，你應在向議會提供個人資料之前獲得父母或監護人的同意。<br/>
                <br/>
                議會會將你的個人資料用於在收集你的個人資料時或之前，向你提供的<strong>收集個人資料聲明</strong>中指定的特定目的。<br/>
                <br/>
                <strong>3. 收集的個人資料類別</strong><br/>
                視乎具體目的，你或會被議會邀請你提供個人資料，包括但不限於你的姓名、性別、年齡（或年齡範圍）、出生日期、聯絡資料、電郵／郵遞／住所／通訊地址、聯絡電話、會員號碼、教育程度、職業、用戶名稱、密碼及追蹤資料等。<br/>
                <br/>
                <strong>4. 使用目的</strong><br/>
                在下述情況中，議會可能會收集你的個人資料作特定用途（並非詳盡無遺）：<br/>
                <br/>
                ．建造業工人註冊；<br/>
                ．報讀教育課程／宣傳計劃（如有需要時進行內部配對程式）及購買／認購議會其他不同類型的服務；<br/>
                ．在由招聘到離職後的過程中用於與就業相關及人力資源管理目的；<br/>
                ．在獲得你的同意後用於直接促銷目的；<br/>
                ．處理各持份者的查詢及投訴；<br/>
                ．進行調查、研究或分析，以改善我們的法定職能；<br/>
                ．遵守適用於香港境內或境外的法律要求，可能由政府當局、法院、執法部門、監管機構、公營機構或法定機構提出的要求；<br/>
                ．履行我們在合約上的職責； 及<br/>
                ．用於履行我們的法定職能的任何其他目的。<br/>
                <br/>
                如最終使用目的與預期收集目的沒有直接關係，我們會根據《個人資料（私隱）條例》取得你的訂明同意。<br/>
                <br/>
                <strong>5. 透過 Cookies 收集資訊</strong><br/>
                議會使用Cookies（可識別設備，但不能識別網站個別使用者的身份）收集有關網站使用者瀏覽議會網站的次數、使用者對網站偏好及議會網站提供的在線服務等的統計。<br/>
                <br/>
                你可以選擇接受或拒絕 Cookies。如果你拒絕Cookies，你將無法使用議會網站的某些功能，例如儲存你在使用議會網站及登入議會網站一些網上服務的偏好。<br/>
                <br/>
                <strong>6. 外判安排</strong><br/>
                為了任何特定用途，你的個人資料可能會被議會轉移及儲存於下述第三方（或其中任何一方），不論在香港境內或境外：<br/>
                <br/>
                ．任何或所有議會的關聯公司及／或附屬公司；<br/>
                ．任何代表議會營運或維持會員資格、活動註冊、導覽預訂、研究及／或分析或代表議會進行後端服務、行政服務、驗證服務、雲端服務或信息技術服務的第三方服務供應商、承包商或分包商，或向議會提供必要的支援或服務，包括保險、銀行或議會使用的任何第三方支付網關服務，以便我們提供服務，以及代表議會履行合約義務之實體；<br/>
                ．議會的專業顧問，包括但不限於律師、會計師和審計師; 或<br/>
                ．任何對議會負有保密責任的一方。<br/>
                <br/>
                議會將採取嚴格措施，保護委託予該等第三方服務供應商的個人資料，包括議會與他們分享的任何個人資料的保密性、安全性和合法處理的有關義務。<br/>
                <br/>
                <strong>7. 保留</strong><br/>
                議會將保留你的個人資料，保留時間並不會超過其使用目的所需的時間。個人資料的保留期限可能因業務需要或法律要求而異。<br/>
                <br/>
                我們執行保留個人資料的政策，並不時對該政策進行檢視；個人資料將按政策適時銷毀。<br/>
                <br/>
                <strong>8. 保安和儲存</strong><br/>
                根據本私隱政策聲明及收集個人資料聲明，載有你個人資料的文件紀錄將儲存在安全的地方，我們及與我們分享你個人資料的人士均被限制查閱有關資料。載有你個人資料的電子紀錄將儲存於議會及／或相關議會服務供應商置於安全處所的伺服器（不論在香港境內或境外），並受適當的資訊科技保安措施（包括但不限於加密及登入管制措施）保護。<br/>
                <br/>
                議會網站在網路傳輸過程中使用TLS協定加密數據，以保護你的個人資料。你透過議會網站向議會提供的所有個人資料均受到保護，只有獲授權人員才能查閱。<br/>
                <br/>
                <strong>9. 資料查閱和更正</strong><br/>
                根據《個人資料（私隱）條例》，你可填妥「<a href="https://www.pcpd.org.hk/chinese/publications/files/Dformc.pdf" target="_blank" rel="noopener">查閱資料申請表</a>」行使查閱個人資料的權利。議會可能會就向你提供要求查閱的個人資料而收取行政費用。你亦可在查閱資料後要求對該等個人資料作出更正。<br/>
                <br/>
                上述要求應發送至：<br/>
                <br/>
                主管（查閱資料要求）<br/>
                建造業議會<br/>
                九龍觀塘駿業街56號<br/>
                中海日升中心38樓<br/>
                <br/>
                或電郵至 <a href="mailto:enquiry@cic.hk" target="_blank" rel="noopener">enquiry@cic.hk</a><br/>
                <br/>
                <strong>10. 查詢</strong><br/>
                如欲查詢本私隱政策聲明，可透過上述通訊地址或電郵至 <a href="mailto:enquiry@cic.hk" target="_blank" rel="noopener">enquiry@cic.hk</a>。<br/>
                <br/>
              </ng-container>
            </div>
            <div
              *ngIf="isTandc2Error && !isTandc2Read"
              class="text-red-600 col-span-10 mt-2"
            >
              {{ 'Please read the Notice to Applicants to the end.' | translate }}
            </div>
            <!-- <div class="mt-2">
<input type="checkbox" name="tandc2" (change)="onTandc2Change($event)" class="rounded checked:bg-green-600 checked:hover:bg-green-600 checked:active:bg-green-600 checked:focus:bg-green-600 focus:outline-none focus:ring-green-600" /><span class="text-red-500 ml-1">*</span>
<span class="text-gray-600 ml-2">{{ 'I have read and understand the Notice to Applicants' | translate }}</span>
</div>
<div
*ngIf="!isTandc2 && isSubmitted"
class="text-red-600 col-span-10 mt-2"
>
{{ 'Please agree with the Notice to Applicants.' | translate }}
            </div> -->
            <div class="mt-6 flex flex-row justify-center md:justify-start">
              <button
                class="flex flex-row justify-center w-40 text-white bg-notus-primary-600 hover:bg-notus-primary-700 focus:outline-none focus:ring-notus-primary-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 items-center"
                *ngIf="!isLoading"
                (click)="onRegisterStep3Back()"
              >
                {{ 'Back' | translate }}
              </button>
              <div class="flex-grow"></div>
              <button
                class="flex flex-row justify-center w-40 text-white bg-notus-primary-600 hover:bg-notus-primary-700 focus:outline-none focus:ring-notus-primary-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 items-center"
                *ngIf="!isLoading && !disableUserInfo"
                (click)="onRegisterStep3Confirm()"
              >
                {{ 'Agree and Register' | translate }}
              </button>
              <button
                type="submit"
                disabled
                class="flex flex-row justify-center w-40 text-white bg-gray-300 focus:outline-none focus:ring-notus-primary-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-white"
                *ngIf="isLoading"
              >
                <flowbite-loading size="1.25rem"></flowbite-loading>
              </button>
            </div>
          </div>
        </div>

        <div class="flex items-center justify-between">
          <!-- <div class="flex items-start">
<p class="text-sm font-light text-gray-500 dark:text-gray-400">
{{ 'Already registered?' | translate }}
<a
(click)="onLogin($event)"
class="font-medium text-notus-primary-600 hover:underline dark:text-primary-500"
>{{ 'Login' | translate }}</a
>
</p>
          </div> -->
          <!-- <a
href="#"
class="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
>{{ 'Forgot password?' | translate }}</a
          > -->
        </div>
      </div>
    </div>
  </div>
</div>
